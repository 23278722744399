import 'jquery-mask-plugin'

$(() => {
    if($('.cpf')) $('.cpf').mask('000.000.000-00')
    if($('.phone')) $('.phone').mask('(00) 00000-0000')
    if($('.cep')) $('.cep').mask('00000-000')
    if($('.money')) $('.money').mask('000.000.000.000.000,00', {reverse: true})

    if($('#tipopcd_id').val()) $('#campos_pcd').css({ display: 'block' })

    if(getIdade($('#nascimento').val()) < 18) {
        $('#campos_responsavel').css({ display: 'block' })
        $('#menor').prop('checked', true)
    }

    if ($('input[name="check_matriculado"]:checked').val() == 'true') {
        console.log($('input[name="check_matriculado"]:checked').val());
        $('#matriculado-true').removeClass('d-none')
        $('#matriculado-false').addClass('d-none')
    }
    else if ($('input[name="check_matriculado"]:checked').val() == 'false') {
        $('#matriculado-true').addClass('d-none')
        $('#matriculado-false').removeClass('d-none')
    }

    if ($('input[name="pcd"]:checked').val() == 'true') {
        $('#pcd-true').removeClass('d-none')
        $('#pcd-false').addClass('d-none')
    }
    else if ($('input[name="pcd"]:checked').val() == 'false') {
        $('#pcd-true').addClass('d-none')
        $('#pcd-false').removeClass('d-none')
    }

    $('input[name="check_ciente"]').change(function () {
        if ($(this).val() == 'true') {
            $('#ciente-true').removeClass('d-none');
            $('#ciente-false').addClass('d-none');
        } else {
            $('#ciente-true').addClass('d-none');
            $('#ciente-false').removeClass('d-none');
        }
    });

    if ($('#tiporesidencia_id').val() == 1) $('#campo_aluguel').css({ display: 'block' })
})

$('#toggle_password').on('click', () => {
    if ($('#toggle_password').find('i').hasClass('fa-eye-slash')) {
        $('#password').prop('type', 'text')

        $('#toggle_password').find('i').removeClass('fa-eye-slash')
        $('#toggle_password').find('i').addClass('fa-eye')
    }
    else if ($('#toggle_password').find('i').hasClass('fa-eye')) {
        $('#password').prop('type', 'password')

        $('#toggle_password').find('i').removeClass('fa-eye')
        $('#toggle_password').find('i').addClass('fa-eye-slash')
    }
})

$('#toggle_password_confirmation').on('click', () => {
    if ($('#toggle_password_confirmation').find('i').hasClass('fa-eye-slash')) {
        $('#password_confirmation').prop('type', 'text')

        $('#toggle_password_confirmation').find('i').removeClass('fa-eye-slash')
        $('#toggle_password_confirmation').find('i').addClass('fa-eye')
    }
    else if ($('#toggle_password_confirmation').find('i').hasClass('fa-eye')) {
        $('#password_confirmation').prop('type', 'password')

        $('#toggle_password_confirmation').find('i').removeClass('fa-eye')
        $('#toggle_password_confirmation').find('i').addClass('fa-eye-slash')
    }
})

$('#toggle_current_password').on('click', () => {
    if ($('#toggle_current_password').find('i').hasClass('fa-eye-slash')) {
        $('#current_password').prop('type', 'text')

        $('#toggle_current_password').find('i').removeClass('fa-eye-slash')
        $('#toggle_current_password').find('i').addClass('fa-eye')
    }
    else if ($('#toggle_current_password').find('i').hasClass('fa-eye')) {
        $('#current_password').prop('type', 'password')

        $('#toggle_current_password').find('i').removeClass('fa-eye')
        $('#toggle_current_password').find('i').addClass('fa-eye-slash')
    }
})

$('#cpf').on('blur', (event) => {
    validarCPF($('#cpf').val())
})

const validarCPF = (value) => {
    value = value.replace('.', '')
    value = value.replace('.', '')
    var cpf = value.replace('-', '')
    var retorno = true
    var x, y

    if (cpf != '') {
        while (cpf.length < 11)
            cpf = '0' + cpf

        var expReg = /^0+$|^1+$|^2+$|^3+$|^4+$|^5+$|^6+$|^7+$|^8+$|^9+$/
        var a = []
        var b = new Number
        var c = 11

        for (var i=0; i<11; i++) {
            a[i] = cpf.charAt(i)
            if (i < 9) b += (a[i] * --c)
        }

        if ((x = b % 11) < 2) { a[9] = 0 } else { a[9] = 11 - x }
        b = 0
        c = 11
        for (y = 0; y < 10; y++) b += (a[y] * c--)
            if ((x = b % 11) < 2) { a[10] = 0; } else { a[10] = 11-x; }

        if ((cpf.charAt(9) != a[9]) || (cpf.charAt(10) != a[10]) || cpf.match(expReg)) retorno = false

        if (retorno == false) {
            alert('CPF inválido. Informe o cpf novamente')
            $('#cpf').val('')
        }
    }

    return retorno;
}

if($('#tipopcd_id').val() > 0) {
    $('#pcd').prop('checked', true)
    $('#campos_pcd').css({ display: 'block' })
}

$('#pcd').on('change', () => {
    if($('#pcd').is(':checked')) {
        $('#campos_pcd').css({ display: 'block' })
    }
    else {
        $('#campos_pcd').css({ display: 'none' })
        $('#tipopcd_id').val(null)
    }
})

function limpa_formulário_cep() {
    // Limpa valores do formulário de cep.
    $('#enderecoA').val('');
    $('#numero').val('');
    $('#bairro').val('');
    $('#cidade').val('');
    $('#uf').val('');
    //$("#ibge").val("");
}

$('#cep_request').on('click', () => {

    //Nova variável "cep" somente com dígitos.
    const cep = $('#cep').val().replace(/\D/g, '');

    //Verifica se campo cep possui valor informado.
    if (cep != '') {

        //Expressão regular para validar o CEP.
        const validacep = /^[0-9]{8}$/;

        //Valida o formato do CEP.
        if(validacep.test(cep)) {

            //Preenche os campos com "..." enquanto consulta webservice.
            $('#enderecoA').val('...');
            $('#numero').val('');
            $('#bairro').val('...');
            $('#cidade').val('...');
            $('#uf').val('...');

            //Consulta o webservice viacep.com.br/
            $.getJSON('//viacep.com.br/ws/' + cep + '/json/?callback=?', (dados) => {

                if (!('erro' in dados)) {
                    //Atualiza os campos com os valores da consulta.
                    $('#enderecoA').val(dados.logradouro)
                    $('#bairro').val(dados.bairro)
                    $('#cidade').val(dados.localidade)
                    $('#uf').val(dados.uf)

                } //end if.
                else {
                    //CEP pesquisado não foi encontrado.
                    limpa_formulário_cep()
                    alert('CEP não encontrado.')
                }
            });
        } //end if.
        else {
            //cep é inválido.
            limpa_formulário_cep()
            alert('Formato de CEP inválido.')
        }
    } //end if.
    else {
        //cep sem valor, limpa formulário.
        limpa_formulário_cep()
    }
})

$('#tiporesidencia_id').on('change', () => {
    if($('#tiporesidencia_id').val() == 1) {
        $('#campo_aluguel').css({ display: 'block' })
    }
    else {
        $('#campo_aluguel').css({ display: 'none' })
        $('#valor_aluguel').val(null)
    }
})

$('input[name="pcd"]').on('change', () => {
    if ($('input[name="pcd"]:checked').val()) {
        $('#campos_pcd').css({ display: 'block' })
    }
    else {
        $('#campos_pcd').css({ display: 'none' })
        $('#tipopcd_id').val(null)
    }
})

const getIdade = (date) => {
    const hoje = new Date()
    const nascimento = new Date(date)
    let idade = hoje.getFullYear() - nascimento.getFullYear()
    const mes = hoje.getMonth() - nascimento.getMonth()
    if (mes < 0 || (mes === 0 && hoje.getDate() < (nascimento.getDate() + 1))) {
        idade--
    }
    return idade
}

$('#nascimento').on('change', () => {
    if (getIdade($('#nascimento').val()) < 18) {
        $('#campos_responsavel').css({ display: 'block' })
        $('#menor').prop('checked', true)
    }
    else {
        $('#campos_responsavel').css({ display: 'none' })
        $('#responsavel').val(null)
        $('#grau_responsavel').val(null)
        $('#cpf_responsavel').val(null)
        $('#rg_responsavel').val(null)
        $('#menor').prop('checked', false)
    }
})

$('input[name="check_matriculado"]').on('change', () => {
    if ($('input[name="check_matriculado"]:checked').val() == 'true') {
        $('#matriculado-true').removeClass('d-none')
        $('#matriculado-false').addClass('d-none')
    }
    else if ($('input[name="check_matriculado"]:checked').val() == 'false') {
        $('#matriculado-true').addClass('d-none')
        $('#matriculado-false').removeClass('d-none')
    }
})

$('input[name="pcd"]').on('change', () => {
    if ($('input[name="pcd"]:checked').val() == 'true') {
        $('#pcd-true').removeClass('d-none')
        $('#pcd-false').addClass('d-none')
    }
    else if ($('input[name="pcd"]:checked').val() == 'false') {
        $('#pcd-true').addClass('d-none')
        $('#pcd-false').removeClass('d-none')
    }
})

$('#calcular_socioeconomico').on('click', () => {
    if ($('#rendafamiliarpessoa').val() == '' || $('#moracom').val() == '') {
        $('#socioeconomico-missing').removeClass('d-none')
        $('#socioeconomico-approved').addClass('d-none')
        $('#socioeconomico-denied').addClass('d-none')
    }
    else {
        const rendaTotal = $('#rendafamiliarpessoa').val().replaceAll('.', '').replaceAll(',', '.')
        console.log(rendaTotal)
        const totalMoradores = parseFloat($('#moracom').val()) + 1
        const salarioMinimo2022 = 1320

        $('#rendafamiliarpessoa').attr('readonly', true)
        $('#moracom').attr('readonly', true)

        $('#calcular_socioeconomico').addClass('d-none')
        $('#reinserir_socioeconomico').removeClass('d-none')

        $('#message_calcular').addClass('d-none')
        $('#message_reinserir').removeClass('d-none')

        if ((rendaTotal / totalMoradores) > salarioMinimo2022 * 4) {
            $('#socioeconomico-missing').addClass('d-none')
            $('#socioeconomico-approved').addClass('d-none')
            $('#socioeconomico-denied').removeClass('d-none')
        }
        else {
            $('#socioeconomico-missing').addClass('d-none')
            $('#socioeconomico-approved').removeClass('d-none')
            $('#socioeconomico-denied').addClass('d-none')
        }
    }
})

$('#reinserir_socioeconomico').on('click', () => {
    $('#rendafamiliarpessoa').attr('readonly', false)
    $('#moracom').attr('readonly', false)

    $('#calcular_socioeconomico').removeClass('d-none')
    $('#reinserir_socioeconomico').addClass('d-none')

    $('#socioeconomico-missing').addClass('d-none')
    $('#socioeconomico-approved').addClass('d-none')
    $('#socioeconomico-denied').addClass('d-none')

    $('#message_calcular').removeClass('d-none')
    $('#message_reinserir').addClass('d-none')
})

$('#confirm_data').on('click', () => {
    if ($('#confirm_data').is(':checked') && $('#confirm_data_btn').hasClass('disabled')) {
        $('#confirm_data_btn').removeClass('disabled')
    }
    else {
        $('#confirm_data_btn').addClass('disabled')
    }
})

$('#copy-button-boleto').on('click', () => {
    $('#copy-confirmation-boleto').removeClass('d-none')

    const r = document.createRange()
    r.selectNode(document.getElementById('copy-message-boleto'))
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(r)
    document.execCommand('copy')
    window.getSelection().removeAllRanges()
})

$('#copy-button-pix').on('click', () => {
    $('#copy-confirmation-pix').removeClass('d-none')

    const r = document.createRange()
    r.selectNode(document.getElementById('copy-message-pix'))
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(r)
    document.execCommand('copy')
    window.getSelection().removeAllRanges()
})

$("#pagar-boleto").on('hide.bs.modal', function(){
    $('#copy-confirmation-boleto').addClass('d-none')
})

$("#pagar-pix").on('hide.bs.modal', function(){
    $('#copy-confirmation-pix').addClass('d-none')
})

$('#cursoInfo').on('click', () => {
    $('#cursoInfoMore').toggleClass('d-none')
    $('#cursoInfoLess').toggleClass('d-none')
})

$("#btn_gerar_pagamento").on('click', function () {
    $("#myModal").modal('show');
    $("#boleto").addClass("hide");

    $.ajax({
        url: "../../app/Http/Controllers/Web/RenovacaoController.php",
        data: { descricao: descricao, valor: valor, quantidade: quantidade, nome_cliente: nome_cliente, cpf: cpf, telefone: telefone, vencimento: vencimento },
        type: 'post',
        dataType: 'json',
        success: function (resposta) {
            $("#myModal").modal('hide');
            console.log(resposta)
            if (resposta.code == 200) {
                $("#boleto").removeClass("hide");
                //"code":200,"data":{"barcode":"03399.32766 55400.000000 60348.101027 6 69020000009000","link":"https:\/\/visualizacaosandbox.gerencianet.com.br\/emissao\/59808_79_FORAA2\/A4XB-59808-60348-HIMA4","expire_at":"2016-08-30","charge_id":76777,"status":"waiting","total":9000,"payment":"banking_billet"-->
                var html = "<th>" + resposta.data.charge_id + "</th>"
                html += "<th>" + resposta.data.barcode + "</th>"
                html += "<th>" + resposta.data.link + "</th>"
                html += "<th>" + resposta.data.expire_at + "</th>"
                html += "<th>" + resposta.data.status + "</th>"
                html += "<th>" + resposta.data.total + "</th>"
                html += "<th>" + resposta.data.payment + "</th>";
                $("#result_table").html(html);

            } else {
                $("#myModal").modal('hide');
                alert("Code: " + resposta.code)
            }
        },
        error: function (resposta) {
            $("#myModal").modal('hide');
            alert("Ocorreu um erro - Mensagem: " + resposta.responseText)
        }
    });
})
